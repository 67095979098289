
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Location } from 'vue-router';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import * as YAML from 'yaml';
import FileSelect from '@/components/catalog/FileSelect/FileSelect.vue';
import { RouteNames } from '@/constants';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GRadioGroup from '@/components/gsk-components/GskRadioGroup.vue';
import GCheckbox from '@/components/gsk-components/GskCheckbox.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import FileUpload from '@gsk-tech/gsk-file-upload/gsk-file-upload';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import * as GITHUB_API from '@/api/github.api';
import { safeUrl } from '@/api/service/urls';
import { getProxyUrl } from '@/api/service/urls';
import { DynamicRegFormModule } from '@/store/modules/dynamic-registration.module';

@Component({
    components: {
        'monaco-editor': () => import('vue-monaco'),
        FullScreenForm,
        GRadioGroup,
        GButton,
        FileUpload,
        GTextfield,
        Grid,
        GridCell,
        FileSelect,
        GCheckbox,
    },
})
export default class ApiRegistrationOptionsView extends Vue {

    public configOptions: any[] = ['Manual Configuration', 'Import OpenAPI Spec'];
    public selectedConfig = this.configOptions[0];

    public sourceOptions: any[] = ['Upload File', 'Specify Github Link'];
    public selectedSource = this.sourceOptions[0];
    public gitHubLink = '';
    public specUrl = '';
    public specLoadError = true;
    public code = '';
    
    get listingPath(): Location {
        return {
            name: RouteNames.ProjectEnvDetails,
        };
    }

    get isCreateButtonDisabled() {
        return this.selectedConfig === this.configOptions[0]? false: this.specLoadError;
    }

    get publishApiListing() {
        return DynamicRegFormModule.publishApiListingData;
    }

    createNewApiProject() {
        this.$router.push({ name: RouteNames.RegisterNewApi });
    }

    async mounted() {
        await DynamicRegFormModule.setPublishApiListingVal(false);
        await DynamicRegFormModule.setOpenApiSpecVal(undefined);
    }

    async loadCode(spec: string) {
        this.code = spec;
        await DynamicRegFormModule.setOpenApiSpecVal(YAML.parse(spec));
        this.loadIntoRapidoc(spec);
    }
    public loadIntoRapidoc(str: string): string {
        this.$nextTick(() => {
            const openRefRapidoc = this.$refs['open-api-rapidoc'];
            if (openRefRapidoc) {
                try {
                    (openRefRapidoc as any).loadSpec(JSON.parse(str) || {});
                } catch (jsonParseError) {
                    try {
                        (openRefRapidoc as any).loadSpec(YAML.parse(str) || {});
                    }
                    catch (yerr) {
                        console.error(yerr);
                    }
                }
            }
        });
        return '';
    }

    public onRapiDocSpecLoaded(e: CustomEvent): void {
        this.specLoadError = e.detail.specLoadError;
    }

    handleUrlUpdate(value: string) {
        this.gitHubLink = value.trim();
        this.getExternalLink();
    }

     public async updatePublishApiListing(){
        await DynamicRegFormModule.setPublishApiListingVal(!this.publishApiListing);
    }

    async getExternalLink() {
        if (this.gitHubLink) {
            const formattedUrl = safeUrl(this.gitHubLink);
            this.specUrl = getProxyUrl(formattedUrl);
            const result = (await GITHUB_API.getExternalDocumentation(formattedUrl)).data;
            const spec = typeof result === 'string' ? result : YAML.stringify(result);
            this.loadCode(spec);
        }
    }

    async onConfigChange(val: string) {
        if(val === this.configOptions[0]) {
            this.code = '';
            await DynamicRegFormModule.setPublishApiListingVal(false);
            await DynamicRegFormModule.setOpenApiSpecVal(undefined);
            this.specLoadError = true;
        }
    }
 }
